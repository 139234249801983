import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dialogUser:false,
    showPlayer: true,
    user: {},
    userEdit: {},
    videoId:'',
    avatar:'img/logo-horizontal.jpeg',
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barCourseColor: 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 1)',
    barImage: 'https://adcosprofissionalctv.com.br/assets/img/fundo.jpg',
    logoHorizontal: 'assets/img/logo-mini.jpeg',
    logoHorizontalBranca: 'assets/img/logo-mini.jpeg',
    drawer: null,
    drawer2: null,
    courseTitle:'',
    // cursos:[
    //   {
    //     titulo:'Confabulando sobre VB-MAPP',
    //     descricao: 'Programa de Avaliação e Colocação de Marcos do Comportamento Verbal',
    //     valor: 699.00,
    //     duracao: 60,
    //     thumbnail: 'https://firebasestorage.googleapis.com/v0/b/confabular-3367d.appspot.com/o/Semana-da-avalia%C3%A7%C3%A3o-em-TEA.webp?alt=media&token=67b84301-1caf-4a96-b401-4861758989ae',
    //     secoes:[
    //       {
    //         titulo:'Modulo 1',
    //         aulas:[
    //           {
    //             titulo: 'Avaliação de Preferência de Pessoas com Autismo: Como escolher os reforçadores?',
    //             subtitulo: 'Programa de Avaliação e Colocação de Marcos do Comportamento Verbal'
    //           }
    //         ]
    //       }
    //     ]
    //   }
    // ]
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },
    SET_DRAWER2(state, payload) {
      state.drawer2 = payload
    },
    SET_COURSE_TITLE(state, payload){
      state.courseTitle = payload
    },
    SET_PLAYER_SHOW(state, payload){
      state.showPlayer = payload
    },
    SET_VIDEO_ID(state, payload){
      state.videoId = payload
    },
    SET_USER(state, payload){
      state.user = payload
    },
    SET_USER_EDIT(state, payload){
      state.userEdit = payload
    },
    SET_DIALOG_USER(state, payload){
      if(!payload){
        state.userEdit =  {}
      }
      state.dialogUser = payload
    }
  },
  actions: {

  },
})
