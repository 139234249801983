import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase/app'
import firebaseAnalytics from 'firebase/analytics'
import firebaseAuth from 'firebase/auth'
import firebaseDb from 'firebase/database'
import store from './store'

Vue.use(Router)
const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Meus Cursos',
          path: '',
          meta: {
            requiresAuth: true,
            isAdmin: false,
            franquia: false,
          },
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Catálogo
        {
          name: 'Catálogo',
          path: 'catalog',
          meta: {
            requiresAuth: true,
            isAdmin: false,
            franquia: false,
          },
          component: () => import('@/views/dashboard/Catalogo'),
        },
        // Catálogo Disponível
        {
          name: 'Catálogo Disponível',
          path: 'pages/catalog',
          meta: {
            requiresAuth: true,
            isAdmin: false,
            franquia: false,
          },
          component: () => import('@/views/dashboard/Catalogo'),
        },
        // Pages
        {
          name: 'Perfil do Usuário',
          path: 'pages/profile',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        // Pages
        {
          name: 'Listagem de usuários',
          path: 'pages/users',
          meta: {
            requiresAuth: true,
            isAdmin: true,
            franquia: true,
          },
          component: () => import('@/views/dashboard/pages/Users'),
        },
        {
          name: 'Listagem de cursos',
          path: 'pages/courses',
          meta: {
            requiresAuth: true,
            isAdmin: true,
            franquia: false,
          },
          component: () => import('@/views/dashboard/pages/Courses'),
        },
        {
          name: 'Listagem de Franquias',
          path: 'pages/franquias',
          meta: {
            requiresAuth: true,
            isAdmin: true,
            franquia: false,
          },
          component: () => import('@/views/dashboard/pages/Franquias'),
        },
        {
          name: 'Curso',
          path: 'pages/courses/:curso',
          meta: {
            requiresAuth: true,
            isAdmin: true,
            franquia: false,
          },
          component: () => import('@/views/dashboard/pages/Course'),
        },
        {
          name: 'Listagem de Matriculados',
          path: 'pages/matriculados/:curso',
          meta: {
            requiresAuth: true,
            isAdmin: true,
            franquia: false,
          },
          component: () => import('@/views/dashboard/pages/Matriculados'),
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/component/Typography'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },
        // Upgrade
        {
          name: 'Upgrade',
          path: 'upgrade',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/Upgrade'),
        },
      ],
    },
    {
      path: '/login',
      name: 'Login',
      meta: {
        requiresAuth: false,
      },
      component: () => import('@/views/default/pages/Login'),
    },
    {
      path: '/certificado/:curso/:user',
      name: 'Certificado',
      meta: {
        requiresAuth: false,
      },
      component: () => import('@/views/default/pages/Certificado'),
    },
    {
      path: '/pages/login',
      name: 'Logout',
      meta: {
        requiresAuth: false,
      },
      component: () => import('@/views/default/pages/Login'),
    },
    {
      name: 'Courses',
      path: '/curso',
      meta: {
        requiresAuth: true,
      },
      component: () => import('@/views/courses/Index'),
      children: [
        //Aula Teste
        {
          path: '/curso/:curso/aula/:id',
          name: 'Aula',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/courses/pages/Course')
        },
        //Aula Teste
        {
          path: '/curso/:curso/aula/',
          name: 'AulaInicial',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/courses/pages/Course')
        },
      ]
    },
  ],
})
router.beforeEach((to, from, next) => {
  let currentUser = firebase.auth().currentUser
  let user = {}
  const requiresAuth = to.meta.requiresAuth
  const isAdmin = to.meta.isAdmin

  // console.log(currentUser)
  if(currentUser && !store.state.user.uid){
    //store.state.user = currentUser
    store.state.user.refreshToken = currentUser.refreshToken
    store.state.user.uid = currentUser.uid
    firebase.database().ref('/users/' + currentUser.uid).once('value').then((snapshot) => {
      if(snapshot.val()){
        user.phoneNumber = snapshot.val().phoneNumber || ''
        user.cpfcnpj =snapshot.val().cpfcnpj || ''
        user.photoURL =snapshot.val().photoURL || ''
        user.displayName =snapshot.val().displayName || ''
        user.isAdmin =snapshot.val().isAdmin
        user.email =snapshot.val().email
        user.refreshToken = currentUser.refreshToken
        user.uid = snapshot.val().uid || ''
        user.cursos = snapshot.val().cursos || []
        user.franquia = snapshot.val().franquia || []
        // console.log(user)
        store.commit("SET_USER",user)
      }

    })
    
  }

  //É necessário autenticação?
  if (requiresAuth && !currentUser) next('login')
  else if(requiresAuth && isAdmin && !store.state.user.isAdmin) next('/')
  else if(to.path == '/login' && currentUser) next('/')
  else next()
})


export default router