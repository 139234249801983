// Conveniently import this file anywhere to use db

import firebase from 'firebase/app'
import 'firebase/database'
var firebaseConfig = {
  apiKey: "AIzaSyCkMl4BQB3DER7jPqVnvEsxFzOYcXs-_78",
  authDomain: "mcs-assessoria-e-treinamentos.firebaseapp.com",
  databaseURL: "https://mcs-assessoria-e-treinamentos-default-rtdb.firebaseio.com",
  projectId: "mcs-assessoria-e-treinamentos",
  storageBucket: "mcs-assessoria-e-treinamentos.appspot.com",
  messagingSenderId: "718175523184",
  appId: "1:718175523184:web:e5bac5bee7d23c72217fb5",
  measurementId: "G-89EZBXK6NR"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();
/*
var admin = require("firebase-admin");
var serviceAccount = require("./serviceAccountKey.json");

admin.initializeApp({
  credential: admin.credential.cert(serviceAccount),
  databaseURL: "https://confabular-3367d-default-rtdb.firebaseio.com"
});
*/
export const db = firebase.database();


// Export types that exists in Firestore - Uncomment if you need them in your app
//  const { Timestamp, GeoPoint } = firebase.firestore
//  export { Timestamp, GeoPoint }
